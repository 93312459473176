<template>
  <app-section class="text-md-center">
    <h2 class="text-h1-secondary mb-5">Jaka jest <em>Twoja</em> historia?</h2>
    <div
      class="text-body-1 font-weight-light mx-md-auto"
      :style="{ maxWidth: '680px' }"
    >
      <app-sierotki v-slot="{ fix }">
        <span class="font-weight-medium" v-html="fix('Każdy z nas')" />&nbsp;
        <span
          v-html="fix('jest uczestnikiem historii, każdy nosi w sobie swoje')"
        />&nbsp;
        <span
          class="font-weight-medium"
          v-html="fix('własne opowieści.')"
        />&nbsp;
        <span
          v-html="
            fix(
              'Ja i Ty mamy przed sobą historię do napisania, czasem gubimy się w jej stronach.',
            )
          "
        />&nbsp;
        <span
          v-html="
            fix(
              'Pozwalamy minionym wydarzeniom kształtować to, co przed nami trzymając je w sobie pozwalając zakłócać naszą codzienność.',
            )
          "
        />&nbsp;
        <span
          class="font-weight-medium"
          v-html="fix('Każda zmiana zaczyna się od decyzji. ')"
        />
        <br class="d-none d-md-block" />
        <span
          class="text-purple font-weight-medium"
          v-html="fix('Chcemy towarzyszyć Tobie w zmianie życia.')"
        />
      </app-sierotki>
    </div>
  </app-section>
</template>
