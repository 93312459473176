<template>
  <app-section-showcase
    subtitle="Tak, jak potrafimy – z empatią, zrozumieniem i indywidualnym podejściem."
    title="Jak pomagamy?"
    wcase
  >
    <div class="d-flex flex-column my-5 my-md-0 w-100">
      <div class="text-subtitle-2 text-black mb-1 mb-md-3">Zakres pomocy</div>
      <div class="d-flex flex-column ga-1 ga-md-2 text-body-1 text-blue">
        <div>Psychiatria dorosłych, dzieci i młodzieży</div>
        <div>Psychiatria rodzinna</div>
        <div>Psychoterapia indywidualna</div>
        <div>Psychoterapia par i rodzin</div>
      </div>
      <div class="text-subtitle-2 text-black mt-6 mb-1 mb-md-3">Inne</div>
      <div class="d-flex flex-column ga-1 ga-md-2 text-body-1 text-blue">
        <div>Warsztaty rozwojowe</div>
        <div>Coaching</div>
        <div>Psychoedukacja</div>
        <div>Kursy i szkolenia</div>
      </div>
    </div>
    <template #button>
      <div class="w-100 text-center text-md-left">
        <app-btn
          append-icon="mdi-arrow-top-right"
          class="text-none"
          color="black"
          :height="38"
          size="small"
          text="Dowiedz się więcej"
          :to="{ name: '/jak-pomagamy', hash: '#howto' }"
        />
      </div>
    </template>
  </app-section-showcase>
</template>
